<template>
  <div id="footer">
    <div class="contact_title boxC">
      <div class="contact_title_ch">联系我们</div>
      <div class="contact_title_en boxC">CONTACT US</div>
    </div>
    <div
      class="contact_info"
      :style="'background-image:url(' + contactInfoBg + ')'"
    >
      <div class="contact_info_title boxC">
        <div class="contact_info_title_logo">
          <img :src="logoImg" alt="" />
        </div>
      </div>

      <div class="contact_other boxC">
        <div class="contact_email box">
          <i class="iconfont icon-mail"></i>
          <div class="contact_other_text">qd@qiedou.top</div>
        </div>
        <div class="contact_phone box">
          <i class="iconfont icon-phone"></i>
          <div class="contact_other_text">400-998-8651</div>
        </div>
        <div class="contact_address box">
          <i class="iconfont icon-location"></i>
          <div class="contact_other_text">
            北京市海淀区世纪经贸大厦B座-1608室
          </div>
        </div>
      </div>
    </div>
    <div class="contact_number boxC">
      <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #fff"
        >京ICP备19003699号-1</a
      >
    </div>
  </div>
</template>

<script>
import LogoImg from "@/assets/logo.png";
import ContactInfoBg from "@/assets/contactInfoBg.png";
export default {
  name: "Footer",
  data() {
    return {
      logoImg: LogoImg,
      contactInfoBg: ContactInfoBg,
    };
  },
};
</script>

<style lang="scss" scoped>
#footer {
  width: 100%;
  height: 100%;
  padding-top: 50px;
  flex-direction: column;
  background: #0d102d;
  .contact_title {
    width: 100%;
    position: relative;
    .contact_title_ch {
      font-size: 48px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #ffffff;
      line-height: 72px;
    }

    .contact_title_en {
      width: 100%;
      position: absolute;
      bottom: 25px;
      font-size: 60px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #ffffff;
      line-height: 1;
      opacity: 0.2;
    }
  }
  .contact_info {
    width: 100%;
    height: 263px;
    margin-top: 45px;
    padding-bottom: 20px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .contact_info_title {
      width: 100%;
      .contact_info_title_logo {
        width: 103px;
      }
    }
    .contact_other {
      width: 100%;
      margin-top: 20px;
      flex-direction: column;
      .contact_address i,
      .contact_email i,
      .contact_phone i {
        font-size: 16px;
        color: #fff;
        opacity: 0.9;
      }
      .contact_other_text {
        margin-left: 6px;
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 28px;
      }
      .contact_email {
        margin-top: 18px;
      }
      .contact_phone {
        margin-top: 18px;
      }
      .contact_address {
        margin-top: 24px;
      }
    }
  }
}

.contact_number {
  padding: 30px 0;
  font-size: 14px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #e7e7e7;
  line-height: 1;
}
</style>