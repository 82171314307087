<template>
  <div id="header" class="box">
    <div id="logo" class="boxC" @click="onNavbarChange({id:1,url:'/'})">
      <img :src="logoImg" alt="" />
    </div>
    <div id="navbar" class="boxS">
      <div
        :class="navbarId === item.id ? 'navbar boxC active' : 'navbar boxC'"
        v-for="(item, index) in navbarList"
        :key="'navbar' + index"
        @click="onNavbarChange(item)"
      >
        <span>{{ item.label }}</span>
        <span class="navbar_icon boxC">
          <i class="iconfont icon-caret-up"></i>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import LogoImg from "@/assets/logo.png";
import NavbarActiveIcon from "@/assets/nabbarActiveIcon.png";
export default {
  name: "Header",
  data() {
    return {
      logoImg: LogoImg,
      navbarActiveIcon: NavbarActiveIcon,
      navbarList: [
        {
          id: 1,
          label: "首页",
          url: "/",
        },
        {
          id: 2,
          label: "产品简介",
          url: "/takewater",
        },
        {
          id: 3,
          label: "产品优势",
          url:"/productadvantages"
        },
        {
          id: 4,
          label: "新闻资讯",
          url:"/news"
        },
        {
          id: 5,
          label: "招商合作",
          url:"/cooperate"
        },
        {
          id: 6,
          label: "联系我们 400-998-8651",
          url:"/contact"
        },
      ],
    };
  },
  computed: {
    navbarId() {
      return this.$store.getters.headerActiveId;
    },
  },
  methods: {
    onNavbarChange(item) {
      this.$store
        .dispatch("public/changeHeaderActiveId", item.id)
        .then((res) => {
          this.$router.push({ path: item.url });
          window.scrollTo(0,0);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#header {
  width: 100%;
  height: 64PX;
  padding-left: 100px;
  position: fixed;
  top: 0;
  background: rgba(13, 16, 46, 1);
  z-index: 99;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  #logo {
    width: 60px;
    height: 100%;
    cursor: pointer;
    img {
      width: 60px;
      height: 45px;
    }
  }
  #navbar {
    width: 70%;
    height: 100%;
    margin-left: 175px;
    .navbar {
      height: 102%;
      // margin-left: 70px;
      position: relative;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 22px;
      border-bottom: 2px solid transparent;
      cursor: pointer;
      .navbar_icon {
        width: 100%;
        font-size: 16px;
        color: #fff;
        position: absolute;
        left: 0;
        bottom: -8px;
        display: none;
      }
    }
    .active {
      border-bottom: 2px solid #fff;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: bold;
      color: #ffffff;
      line-height: 22px;
      .navbar_icon {
        display: flex;
      }
    }
  }
}
</style>