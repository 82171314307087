<template>
  <div id="header" class="boxS">
    <div id="logo" class="boxC" @click="onNavbarChange({ id: 1, url: '/' })">
      <img :src="logoImg" alt="" />
    </div>
    <div class="navBar_icon box" @click="onToggleDrawer">
      <div class="navBar_icon_line1"></div>
      <div class="navBar_icon_line2"></div>
    </div>
    <el-drawer
      :visible.sync="drawer"
      :with-header="true"
      :before-close="handleClose"
      direction="rtl"
      size="60%"
    >
      <div id="navbar">
        <div
          :class="navbarId === item.id ? 'navbar boxC active' : 'navbar boxC'"
          v-for="(item, index) in navbarList"
          :key="'navbar' + index"
          @click="onNavbarChange(item)"
        >
          <span>{{ item.label }}</span>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import LogoImg from "@/assets/logo.png";
import NavbarActiveIcon from "@/assets/nabbarActiveIcon.png";
export default {
  name: "Header",
  data() {
    return {
      logoImg: LogoImg,
      navbarActiveIcon: NavbarActiveIcon,
      drawer: false,
      navbarList: [
        {
          id: 1,
          label: "首页",
          url: "/",
        },
        {
          id: 2,
          label: "产品简介",
          url: "/takewater",
        },
        {
          id: 3,
          label: "产品优势",
          url: "/productadvantages",
        },
        {
          id: 4,
          label: "新闻资讯",
          url: "/news",
        },
        {
          id: 5,
          label: "招商合作",
          url: "/cooperate",
        },
        {
          id: 6,
          label: "联系我们 400-998-8651",
          url: "/contact",
        },
      ],
    };
  },
  computed: {
    navbarId() {
      return this.$store.getters.headerActiveId;
    },
  },
  methods: {
    onToggleDrawer() {
      this.drawer = !this.drawer;
    },
    onNavbarChange(item) {
      this.$store
        .dispatch("public/changeHeaderActiveId", item.id)
        .then((res) => {
          this.drawer = false;
          this.$router.push({ path: item.url });
          window.scrollTo(0, 0);
        });
    },
    handleClose(done) {
      done();
    },
  },
};
</script>

<style lang="scss" scoped>
#header {
  width: 100%;
  height: 64PX;
  padding: 0 64px;
  position: fixed;
  top: 0;
  background: rgba(13, 16, 46, 1);
  z-index: 999999999999999999;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  #logo {
    width: 90px;
    height: 100%;
    cursor: pointer;
    img {
      width: 90px;
      height: 70px;
    }
  }
  .navBar_icon {
    flex-direction: column;
    align-items: flex-end;
    .navBar_icon_line1 {
      width: 43px;
      height: 8px;
      background: #ffffff;
      border-radius: 4px;
    }
    .navBar_icon_line2 {
      width: 31px;
      height: 8px;
      margin-top: 17px;
      background: #ffffff;
      border-radius: 4px;
    }
  }
  #navbar {
    width: 100%;
    height: 100%;
    .navbar {
      width: 100%;
      height: 150px;
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333;
      line-height: 22px;
      cursor: pointer;
    }
    .active {
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: bold;
      color: #000;
      line-height: 22px;
    }
  }
}
</style>
<style scoped>
  #header >>> .el-drawer{
    border-radius: 50px  0 0 50px;
  }
</style>