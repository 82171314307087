
const state = {
    headerActiveId: 1,
    isMobile: true
}

const mutations = {
    CHANGE_HEADERACTIVEID: (state, id) => {
        state.headerActiveId = id
    },
    CHANGE_ISMOBILE: (state, bool) => {
        state.isMobile = bool
    }
}

const actions = {
    changeHeaderActiveId({ commit }, data) {
        commit('CHANGE_HEADERACTIVEID', data)
    },
    changeIsMobile({ commit }, bool) {
        commit('CHANGE_ISMOBILE', bool)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}

