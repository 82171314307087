<template>
  <div id="layout">
    <comp-header v-if="isMobile"></comp-header>
    <comp-header-pc v-if="!isMobile"></comp-header-pc>
    <div id="appMain" :style="isMobile?'margin:0 auto;margin-top:64px;':'margin:0 auto;margin-top:64px;'">
      <app-main />
    </div>
    <comp-footer v-if="isMobile"></comp-footer>
    <comp-footer-pc  v-if="!isMobile"></comp-footer-pc>
  </div>
</template>

<script>
import ResizeMixin from './mixin/ResizeHandler'
import CompHeader from "@/components/CompHeader.vue";
import CompHeaderPc from "@/components/CompHeaderPc.vue";
import CompFooter from "@/components/CompFooter.vue";
import CompFooterPc from "@/components/CompFooterPc.vue";
import { AppMain } from "./components";
export default {
  name: "Layout",
  components: {
    CompHeader,
    CompHeaderPc,
    AppMain,
    CompFooter,
    CompFooterPc
  },
  mixins: [ResizeMixin],
  computed: {
    isMobile() {
      return this.$store.getters.isMobile
    }
  },
};
</script>

<style lang="scss" scoped>
#layout {
  background: rgba(13, 16, 46, 1);
  // background-color: #f6f6f6;
  #appMain {
    width: 100%;
    margin: 0 auto;
    margin-top: 115px;
    background-color: #f6f6f6;
  }
}
</style>